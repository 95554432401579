export default {
  blogerName: 'CARROCAS',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/maarck3',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c3ee2722c',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cef5d29c3',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c2aa00b96',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cb77e7ab7',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c99ea7335',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c2f5c2d61',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c03ef4322',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Regista-te com o código promocional <strong>CARROCAS</strong> e recebe 50 rodadas grátis em cada jogo disponível em baixo!',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CARROCAS',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
